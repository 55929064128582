import React from 'react';

import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import { HashRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
    <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          </Routes>
          </Router>
    </div>
  );
}

export default App;
