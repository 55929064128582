import React from 'react';
import { Link } from 'react-router-dom';

// Define the Home component
const Home = () => {
  return (
    <div>
     <div class="page-wrapper">





     <header id="site-header" class="header header-1">
  <div class="container-fluid">
    <div id="header-wrap" class="box-shadow">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand logo" href="index.html">
              <img id="logo-img" class="img-fluid" src="assets/images/ikanekt_logo.png" alt=""/>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span></span>
              <span></span>
              <span></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="nav navbar-nav mx-auto">
              <Link to="/"> <li class="nav-item"> <a class="nav-link" href="#">Home</a> 
                </li></Link>
                <Link to="/about"> <li class="nav-item"> <a class="nav-link" href="#">About</a> 
                </li></Link>
                <Link to="/services"> <li class="nav-item"> <a class="nav-link" href="#">Services</a> 
                </li></Link>
              
                <Link to="/contact"> <li class="nav-item"> <a class="nav-link" href="#">Contact</a> 
                </li></Link>
                
              </ul>
            </div>
            <a class="btn btn-theme btn-sm" href="#" data-text="Login"> <span>L</span><span>o</span><span>g</span><span>i</span><span>n</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>



<section id="home" class="fullscreen-banner banner p-0 bg-contain bg-pos-rt" data-bg-img="assets/images/bg/01.png">
  <div class="spinner-eff">
    <div class="spinner-circle circle-1"></div>
    <div class="spinner-circle circle-2"></div>
  </div>
 <div class="align-center pt-0"> 
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <img class="img-fluid wow jackInTheBox" data-wow-duration="3s" src="assets/images/banner/01.png" alt=""/>
          </div>
        <div class="col-lg-6 col-md-12 mt-5 mt-lg-0">
          <h4 class="mb-4 wow fadeInUp wow animate__zoomIn animate__slow" data-wow-duration="1.5s">Welcome to Clinical Trial Process and Document Management Application

</h4>
          <p class="lead mb-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">iKanekt’s focus is to streamline Clinical trials process and document management by digitalization.
</p>
          <a class="btn btn-theme wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.5s" href="#" data-text="Request for demo"> <span>R</span><span>e</span><span>q</span><span>u</span><span>e</span>
            <span>s </span><span>t</span>  <span> </span><span>F</span><span>o</span><span>r</span><span> </span><span>D</span><span>e</span><span>m</span><span>o</span>
          </a>
        
        </div>
      </div>
    </div>
  </div>
</section>



<div class="page-content">


<section class="p-0 text-center wow fadeInUp" data-wow-duration="2s">
  <div class="container">
    <div class="row custom-mt-10 z-index-1 md-mt-0">
      <div class="col-md-12">
        <div class="owl-carousel" data-dots="false" data-items="2" data-lg-items="3" data-md-items="2" data-sm-items="1" data-autoplay="true">
          <div class="item">
            <div class="featured-item style-2">
              <div class="featured-icon">
                <i class="flaticon-data"></i>
                <span class="rotateme"></span>
              </div>
              <div class="featured-title wow animate__zoomIn animate__slow">
                <h5>Save time
</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"justify"}} data-aos="zoom-in" data-aos-duration="1000">Implementing a robust CTPM system streamlines various aspects of clinical trials, from participant recruitment to data collection and analysis. By automating repetitive tasks, optimizing workflows, and enhancing communication among stakeholders, CTPM saves valuable time in the trial process.</p>
               
              </div>
            </div>
          </div>
          <div class="item">
            <div class="featured-item style-2">
              <div class="featured-icon">
               <i class="flaticon-collaboration"></i>
                <span class="rotateme"></span>
              </div>
              <div class="featured-title">
                <h5>Bring about the required transparency 
</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"justify"}}>Transparency is crucial in clinical trials to ensure ethical conduct and build trust among participants, regulatory bodies, and the public. CTPM facilitates transparency by providing real-time access to trial progress, protocols, and results, allowing stakeholders to track every stage of the trial accurately.</p>
                
              </div>
            </div>
          </div>
          <div class="item">
            <div class="featured-item style-2">
              <div class="featured-icon">
                <i class="flaticon-market"></i>
                <span class="rotateme"></span>
              </div>
              <div class="featured-title">
                <h5>Reduce cost
</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"justify"}}>Clinical trials are notoriously expensive, often involving substantial investments in personnel, equipment, and regulatory compliance. CTPM helps control costs by improving efficiency, reducing errors, and minimizing delays throughout the trial lifecycle.</p>
               
              </div>
            </div>
          </div>
          <div class="item">
            <div class="featured-item style-2">
              <div class="featured-icon">
                <i class="flaticon-objective"></i>
                <span class="rotateme"></span>
              </div>
              <div class="featured-title">
                <h5>Control to stake holders
</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"justify"}}>With CTPM, stakeholders, including researchers, sponsors, regulatory agencies, and participants, gain greater control over the trial process. Through centralized data management, comprehensive reporting, and adherence to predefined protocols, CTPM empowers stakeholders to make informed decisions and ensure trial integrity.</p>
               
              </div>
            </div>
          </div>
          <div class="item">
            <div class="featured-item style-2">
              <div class="featured-icon">
                <i class="flaticon-analytics"></i>
                <span class="rotateme"></span>
              </div>
              <div class="featured-title">
                <h5>Improve accountability metrics
</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"justify"}}>Accountability is paramount in clinical research to maintain scientific rigor and uphold ethical standards. CTPM enhances accountability by establishing clear roles and responsibilities, documenting all trial activities, and implementing robust quality assurance measures to monitor compliance with regulatory requirements.</p>
              
              </div>
            </div>
          </div>
          <div class="item">
            <div class="featured-item style-2">
              <div class="featured-icon">
                <i class="flaticon-objective"></i>
                <span class="rotateme"></span>
              </div>
              <div class="featured-title">
                <h5>Reduce paper clutter 

</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"justify"}}>Traditionally, clinical trials rely heavily on paper-based documentation, leading to inefficiencies, errors, and storage challenges. CTPM leverages electronic data capture, digital document management, and online collaboration tools to minimize paper clutter, streamline documentation processes, and ensure data integrity.</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="about" class="position-relative bg-contain bg-pos-l custom-py-2" data-bg-img="assets/images/bg/02.png">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12 image-column p-0">
        <div class="img-box box-shadow">
            <div class="box-loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
            <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid" src="assets/images/banner1.jpg" alt=""/>
          </div>
      </div>
      <div class="col-lg-5 ms-auto col-md-12 mt-5 mt-lg-0">
        <div class="section-title mb-4">
          <div class="title-effect">
            <div class="bar bar-top"></div>
            <div class="bar bar-right"></div>
            <div class="bar bar-bottom"></div>
            <div class="bar bar-left"></div>
          </div>
          <h2>Overview of CTPM</h2>
        </div>
       <p style={{textAlign:"justify"}} data-aos="zoom-in" data-aos-duration="1000">CTPM provides a structured and systematic approach to managing clinical trials, integrating best practices, technological advancements, and regulatory standards to ensure the ethical conduct, scientific validity, and regulatory compliance of clinical research studies. By optimizing processes and enhancing transparency, CTPM aims to accelerate the development of safe and effective medical treatments for the benefit of patients worldwide.</p>
      
      </div>
    </div>
  </div>
</section>


<section id="service" class="service position-relative bg-effect overflow-hidden">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12 order-lg-12 image-column right">
        <div class="blink-img">
          <img class="img-fluid blinkblink" src="assets/images/pattern/04.png" alt=""/>
        </div>
        <img class="img-fluid z-index-1 w-100" src="assets/images/svg/01.svg" alt=""/>
      </div>
      <div class="col-lg-6 col-md-12 mt-5 mt-lg-0 order-lg-1">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="featured-item style-3">
              <div class="featured-icon">
                <i class="la la-diamond"></i>
              </div>
              <div class="featured-title">
                <h5 data-aos="zoom-in" data-aos-duration="1000">Graphical representation of Site performance</h5>
              </div>
             
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mt-4">
            <div class="featured-item style-3">
              <div class="featured-icon">
                <i class="la la-credit-card"></i>
              </div>
              <div class="featured-title">
                <h5 data-aos="zoom-in" data-aos-duration="1000">Patient recruitment status</h5>
              </div>
            
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mt-3 mt-md-0">
            <div class="featured-item style-3">
              <div class="featured-icon">
                <i class="la la-desktop"></i>
              </div>
              <div class="featured-title">
                <h5 data-aos="zoom-in" data-aos-duration="1000">Active and Inactive studies</h5>
              </div>
             
            </div>
          </div>
          <div class="col-lg-6 col-md-6 mt-4">
            <div class="featured-item style-3">
              <div class="featured-icon">
                <i class="la la-headphones"></i>
              </div>
              <div class="featured-title">
                <h5 data-aos="zoom-in" data-aos-duration="1000">SAE’s reported</h5>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="text-center position-relative">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-10 mx-auto">
        <div class="section-title">
          <div class="title-effect">
            <div class="bar bar-top"></div>
            <div class="bar bar-right"></div>
            <div class="bar bar-bottom"></div>
            <div class="bar bar-left"></div>
          </div>
          <h2 class="title" data-aos="zoom-in" data-aos-duration="1000">Login's available in CTPM</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="svg-container">
        <svg id="svgC" width="100%" height="100%" viewBox="0 0 620 120" preserveAspectRatio="xMidYMid meet"></svg>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="work-process">
          <div class="box-loader"> <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="step-num-box">
            <div class="step-icon"><span><i class="la la-lightbulb-o"></i></span>
            </div>
            <div class="step-num">01</div>
          </div>
          <div class="step-desc">
            <h4>Sponser</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div class="work-process">
          <div class="box-loader"> <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="step-num-box">
            <div class="step-icon"><span><i class="la la-rocket"></i></span>
            </div>
            <div class="step-num">02</div>
          </div>
          <div class="step-desc">
            <h4>Sites</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div class="work-process">
          <div class="step-num-box">
            <div class="step-icon"><span><i class="la la-check-square"></i></span>
            </div>
            <div class="step-num">03</div>
          </div>
          <div class="step-desc">
            <h4>Ethics Comitee</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>









<section class="grey-bg position-relative overflow-hidden" data-bg-img="assets/images/pattern/01.png">
  <div class="wave-shape">
    <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid" src="assets/images/bg/03.png" alt=""/>
  </div>
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-lg-8 col-md-12">
        <div class="section-title">
          <div class="title-effect">
            <div class="bar bar-top"></div>
            <div class="bar bar-right"></div>
            <div class="bar bar-bottom"></div>
            <div class="bar bar-left"></div>
          </div>
          <h6 data-aos="zoom-in" data-aos-duration="1000">Testimonial</h6>
          <h2 class="title" data-aos="zoom-in" data-aos-duration="1000">You Can See our clients feedback What You Say?</h2> 
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <div id="testimonial-1" class="testimonial-carousel carousel slide" data-bs-ride="carousel" data-bs-interval="2500">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="testimonial style-1">
                    <div class="testimonial-content">
                      <div class="testimonial-quote"><i class="la la-quote-left"></i>
                      </div>
                      <p data-aos="zoom-in" data-aos-duration="1000" style={{textAlign:"justify",fontSize:"12px"}}>"Our experience with ikanekt has been nothing short of exceptional. Their CTPM platform has revolutionized the way we conduct our clinical trials. From seamless participant recruitment to meticulous data management, every aspect of our trials has been streamlined beyond expectation. Thanks to [Your Company Name]'s innovative solutions, we've been able to accelerate our research timelines, improve transparency, and achieve remarkable results. Their dedication to excellence and commitment to client satisfaction make them an invaluable partner in our journey towards advancing medical science."</p>
                      <div class="testimonial-caption">
                        <h5 data-aos="zoom-in" data-aos-duration="1000">Streamlined Our Clinical Trials Beyond Expectation</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="testimonial style-1">
                    <div class="testimonial-content">
                      <div class="testimonial-quote"><i class="la la-quote-left"></i>
                      </div>
                      <p  style={{textAlign:"justify",fontSize:"12px"}} data-aos="zoom-in" data-aos-duration="1000">"Choosing ikanekt for our clinical trial management needs was one of the best decisions we made. Their CTPM platform has had a transformative impact on the efficiency and effectiveness of our trials. With their user-friendly interface and comprehensive features, we've been able to streamline our processes, enhance collaboration among stakeholders, and ensure compliance with regulatory standards. The result? Faster enrollment, better data quality, and ultimately, accelerated development of life-saving treatments. We're grateful for [Your Company Name]'s expertise and support, which have been instrumental in our success."</p>
                      <div class="testimonial-caption">
                        <h5 data-aos="zoom-in" data-aos-duration="1000">Transformative Impact on Trial Efficiency</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="testimonial style-1">
                    <div class="testimonial-content">
                      <div class="testimonial-quote"><i class="la la-quote-left"></i>
                      </div>
                      <p style={{textAlign:"justify",fontSize:"12px"}} data-aos="zoom-in" data-aos-duration="1000">"As a leading research institution, we demand the highest standards in our clinical trials, and ikanekt has consistently exceeded our expectations. Their CTPM platform is more than just a software solution—it's a trusted partner in our pursuit of clinical research excellence. From personalized support to innovative technology, [Your Company Name] has provided us with the tools and insights we need to conduct our trials with precision and integrity. With their help, we've been able to navigate complex regulatory landscapes, mitigate risks, and deliver groundbreaking discoveries that benefit patients worldwide. We're proud to collaborate with [Your Company Name] and look forward to many more successful trials together."</p>
                      <div class="testimonial-caption">
                        <h6 data-aos="zoom-in" data-aos-duration="1000">Trusted Partner in Clinical Research Excellence</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 mt-5 mt-lg-0">
              <div class="controls">
                <div class="anti-01-holder">
                  <div class="anti-01"></div>
                </div>
                <div class="anti-02-holder">
                  <div class="anti-02"></div>
                </div>
                <div class="anti-03-holder">
                  <div class="anti-03"></div>
                </div>
                <ul class="nav">
                  <li data-bs-target="#testimonial-1" data-bs-slide-to="0" class="active">
                    <a href="#">
                      <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid" src="assets/images/testimonial/01.jpg" alt=""/>
                    </a>
                  </li>
                  <li data-bs-target="#testimonial-1" data-bs-slide-to="1">
                    <a href="#">
                      <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid" src="assets/images/testimonial/02.jpg" alt=""/>
                    </a>
                  </li>
                  <li data-bs-target="#testimonial-1" data-bs-slide-to="2">
                    <a href="#">
                      <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid" src="assets/images/testimonial/03.jpg" alt=""/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>







</div>


<footer class="footer footer-1 position-relative" data-bg-img="assets/images/bg/05.png" data-aos="zoom-in" data-aos-duration="1000">
  <div class="subscribe-box">
    <div class="container">
      <div class="row subscribe-inner align-items-center">
        <div class="col-md-6 col-sm-12">
          <h4>Subscribe Our Newsletter</h4>
        </div>
        <div class="col-md-6 col-sm-12 mt-3 mt-md-0">
          <div class="subscribe-form">
            <form id="mc-form" class="group">
              <input type="email" value="" name="EMAIL" class="email" id="mc-email" placeholder="Email Address" required=""/>
              <input class="btn btn-theme" type="submit" name="subscribe" value="Subscribe"/>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="primary-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-logo">
            <img id="footer-logo-img" src="assets/images/ikanekt_logo.png" class="img-fluid" alt=""/>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 ps-md-5 mt-5 mt-md-0 footer-list justify-content-between d-flex">
          <ul class="list-unstyled w-100">            
            <li>About Us
            </li>
            <li>Service
            </li>
           
            <li>Contact Us
            </li>
          </ul>
    
        </div>
        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
          <ul class="media-icon list-unstyled">
            <li>
              <p class="mb-0">Contact person – 
Srirang Ramamoorthy<br/>
Mobile - 9902260163<br/>
Email – srirang@ikanektrnd.com
              </p>
            </li>
          
           
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="secondary-footer">
    <div class="container">
      <div class="copyright">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12"> <span>Copyright 2024  | All Rights Reserved </span>
          </div>
          <div class="col-lg-6 col-md-12 text-lg-end mt-3 mt-lg-0">
            <div class="footer-social">
              <ul class="list-inline">
                <li class="me-2"><a href="#"><i class="fab fa-facebook-f"></i> Facebook</a>
                </li>
                <li class="me-2"><a href="#"><i class="fab fa-twitter"></i> Twitter</a>
                </li>
                <li><a href="#"><i class="fab fa-google-plus-g"></i> Google Plus</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>




</div>




<div class="color-customizer closed">
  <div class="color-button">
    <a class="opener" href="#"> <i class="fas fa-spinner fa-spin"></i>
    </a>
  </div>
  <div class="clearfix color-chooser text-center">
    <h4 class="text-theme font-w-8 mb-4">Softino With <span class="text-black font-w-5">Awesome Colors</span></h4>
    <ul class="colorChange clearfix">
      <li class="theme-default selected" title="theme-default" data-style="color-1"></li>
      <li class="theme-2" title="theme-2" data-style="color-2"></li>
      <li class="theme-3" title="theme-3" data-style="color-3"></li>
      <li class="theme-4" title="theme-4" data-style="color-4"></li>
      <li class="theme-5" title="theme-5" data-style="color-5"></li>
      <li class="theme-6" title="theme-6" data-style="color-6"></li>
    </ul>
    <div class="text-center mt-4">
      <a class="btn btn-theme btn-circle" href="#" data-text="Purchase Now">
       <span>P</span><span>u</span><span>r</span><span>c</span><span>h</span><span>a</span><span>s</span><span>e</span>
       <span> </span><span>N</span><span>o</span><span>W</span>
        </a>
    </div>
  </div>
</div>



<div class="scroll-top"><a class="smoothscroll" href="#top"><i class="flaticon-go-up-in-web"></i></a></div>
    </div>
  );
};

export default Home;
