import React from 'react';
import { Link } from 'react-router-dom';

// Define the Home component
const About = () => {
  return (
    <div>
<div class="page-wrapper">




<header id="site-header" class="header header-1">
  <div class="container-fluid">
    <div id="header-wrap" class="box-shadow">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand logo" href="index.html">
              <img id="logo-img" class="img-fluid" src="assets/images/ikanekt_logo.png" alt=""/>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span></span>
              <span></span>
              <span></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="nav navbar-nav mx-auto">
              <Link to="/"> <li class="nav-item"> <a class="nav-link" href="#">Home</a> 
                </li></Link>
                <Link to="/about"> <li class="nav-item"> <a class="nav-link" href="#">About</a> 
                </li></Link>
                <Link to="/services"> <li class="nav-item"> <a class="nav-link" href="#">Services</a> 
                </li></Link>
              
                <Link to="/contact"> <li class="nav-item"> <a class="nav-link" href="#">Contact</a> 
                </li></Link>
                
              </ul>
            </div>
            <a class="btn btn-theme btn-sm" href="#" data-text="Login"> <span>L</span><span>o</span><span>g</span><span>i</span><span>n</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>


<section class="page-title overflow-hidden position-relative" data-bg-color="#fbf3ed">
  <canvas id="confetti"></canvas>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-12">
        <h1 class="title"><span>A</span>bout Us</h1>
      </div>
   
    </div>
  </div>
  <div class="page-title-pattern">
    <img class="img-fluid" src="assets/images/bg/11.png" alt=""/>
  </div>
</section>



<div class="page-content">








<section class="service position-relative bg-effect overflow-hidden custom-py-1" style={{marginTop:"-80px"}}>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12 order-lg-12 image-column right">
        <div class="blink-img">
          <img class="img-fluid blinkblink" src="assets/images/vismis.png" alt=""/>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mt-5 mt-lg-0 order-lg-1">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="featured-item style-3">
              <div class="featured-icon"> <i class="la la-diamond"></i>
              </div>
              <div class="featured-title">
                <h5>Vision</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"justify"}} data-aos="zoom-in" data-aos-duration="1000">Bring in early access of medical 
products to meet patient needs
</p>
              </div>
            </div>
          </div>
          
          
          <div class="col-lg-6 col-md-6 mt-4">
            <div class="featured-item style-3">
              <div class="featured-icon"> <i class="la la-diamond"></i>
              </div>
              <div class="featured-title">
                <h5>Mission</h5>
              </div>
              <div class="featured-desc">
                <p style={{textAlign:"left"}} data-aos="zoom-in" data-aos-duration="1000">To bring in remote access with transparency, streamline clinical trial process, document management for stakeholders
</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="position-relative overflow-hidden">
  <canvas id="canvas"></canvas>
  <h4 data-aos="zoom-in" data-aos-duration="1000"> iKanekt’s road map since inception</h4>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="timeline-wrap">
          <ul class="timeline timeline--first list-unstyled">
            <li class="timeline-title">
              <h2>2018</h2>
            </li>
            <li class="timeline-inner timeline-left">
              <div class="timeline-content">
                <h3 data-aos="zoom-in" data-aos-duration="1000">iKanekt formation
</h3>
              </div>
            </li>
        
         
          </ul>
        </div>
        <div class="timeline-wrap">
          <ul class="timeline list-unstyled">
            <li class="timeline-title">
              <h2>2019</h2>
            </li>
            <li class="timeline-inner timeline-right">
              <div class="timeline-content">
                <h3 data-aos="zoom-in" data-aos-duration="1000">CTPM development
</h3>
              </div>
            </li>
        
     
          </ul>
        </div>
        <div class="timeline-wrap">
          <ul class="timeline timeline--first list-unstyled">
            <li class="timeline-title">
              <h2>2020</h2>
            </li>
            <li class="timeline-inner timeline-left">
              <div class="timeline-content">
                <h3 data-aos="zoom-in" data-aos-duration="1000">Launched CTPM

</h3>
              </div>
            </li>
        
         
          </ul>
        </div>
        <div class="timeline-wrap">
          <ul class="timeline timeline--first list-unstyled">
            <li class="timeline-title">
              <h2 data-aos="zoom-in" data-aos-duration="1000">2021</h2>
            </li>
            <li class="timeline-inner timeline-right">
              <div class="timeline-content">
                <h3 data-aos="zoom-in" data-aos-duration="1000">Secured first sale - 
Mazumdar Shaw Medical Foundation 
</h3>
              </div>
            </li>
        
         
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

















</div>



<footer class="footer footer-1 position-relative" data-bg-img="assets/images/bg/05.png">
  <div class="subscribe-box">
    <div class="container">
      <div class="row subscribe-inner align-items-center">
        <div class="col-md-6 col-sm-12">
          <h4>Subscribe Our Newsletter</h4>
        </div>
        <div class="col-md-6 col-sm-12 mt-3 mt-md-0">
          <div class="subscribe-form">
            <form id="mc-form" class="group">
              <input type="email" value="" name="EMAIL" class="email" id="mc-email" placeholder="Email Address" required=""/>
              <input class="btn btn-theme" type="submit" name="subscribe" value="Subscribe"/>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="primary-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-logo">
            <img id="footer-logo-img" src="assets/images/ikanekt_logo.png" class="img-fluid" alt=""/>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 ps-md-5 mt-5 mt-md-0 footer-list justify-content-between d-flex">
          <ul class="list-unstyled w-100">            
            <li>About Us
            </li>
            <li>Service
            </li>
           
            <li>Contact Us
            </li>
          </ul>
    
        </div>
        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
          <ul class="media-icon list-unstyled">
            <li>
              <p class="mb-0">Contact person – 
Srirang Ramamoorthy<br/>
Mobile - 9902260163<br/>
Email – srirang@ikanektrnd.com
              </p>
            </li>
         
           
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="secondary-footer">
    <div class="container">
      <div class="copyright">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12"> <span>Copyright 2024  | All Rights Reserved </span>
          </div>
          <div class="col-lg-6 col-md-12 text-lg-end mt-3 mt-lg-0">
            <div class="footer-social">
              <ul class="list-inline">
                <li class="me-2"><a href="#"><i class="fab fa-facebook-f"></i> Facebook</a>
                </li>
                <li class="me-2"><a href="#"><i class="fab fa-twitter"></i> Twitter</a>
                </li>
                <li><a href="#"><i class="fab fa-google-plus-g"></i> Google Plus</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>




</div>




<div class="color-customizer closed">
  <div class="color-button">
    <a class="opener" href="#"> <i class="fas fa-spinner fa-spin"></i>
    </a>
  </div>
  <div class="clearfix color-chooser text-center">
    <h4 class="text-theme font-w-8 mb-4">Softino With <span class="text-black font-w-5">Awesome Colors</span></h4>
    <ul class="colorChange clearfix">
      <li class="theme-default selected" title="theme-default" data-style="color-1"></li>
      <li class="theme-2" title="theme-2" data-style="color-2"></li>
      <li class="theme-3" title="theme-3" data-style="color-3"></li>
      <li class="theme-4" title="theme-4" data-style="color-4"></li>
      <li class="theme-5" title="theme-5" data-style="color-5"></li>
      <li class="theme-6" title="theme-6" data-style="color-6"></li>
    </ul>
    <div class="text-center mt-4">
      <a class="btn btn-theme btn-circle" href="#" data-text="Purchase Now">
       <span>P</span><span>u</span><span>r</span><span>c</span><span>h</span><span>a</span><span>s</span><span>e</span>
       <span> </span><span>N</span><span>o</span><span>W</span>
        </a>
    </div>
  </div>
</div>




<div class="scroll-top"><a class="smoothscroll" href="#top"><i class="flaticon-go-up-in-web"></i></a></div>
    </div>

  );
};

export default About;
