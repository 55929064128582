import React from 'react';
import { Link } from 'react-router-dom';

// Define the Home component
const Services = () => {
  return (
    <div>
<div class="page-wrapper">




<header id="site-header" class="header header-1">
  <div class="container-fluid">
    <div id="header-wrap" class="box-shadow">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand logo" href="index.html">
              <img id="logo-img" class="img-fluid" src="assets/images/ikanekt_logo.png" alt=""/>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span></span>
              <span></span>
              <span></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="nav navbar-nav mx-auto">
              <Link to="/"> <li class="nav-item"> <a class="nav-link" href="#">Home</a> 
                </li></Link>
                <Link to="/about"> <li class="nav-item"> <a class="nav-link" href="#">About</a> 
                </li></Link>
                <Link to="/services"> <li class="nav-item"> <a class="nav-link" href="#">Services</a> 
                </li></Link>
              
                <Link to="/contact"> <li class="nav-item"> <a class="nav-link" href="#">Contact</a> 
                </li></Link>
                
              </ul>
            </div>
            <a class="btn btn-theme btn-sm" href="#" data-text="Login"> <span>L</span><span>o</span><span>g</span><span>i</span><span>n</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>



<section class="page-title overflow-hidden position-relative" data-bg-color="#fbf3ed">
  <canvas id="confetti"></canvas>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-12">
        <h1 class="title" data-aos="zoom-in" data-aos-duration="1000"><span>S</span>ervices</h1>
      </div>
   
    </div>
  </div>
  <div class="page-title-pattern">
    <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid" src="assets/images/bg/11.png" alt=""/>
  </div>
</section>



<div class="page-content">

<section>
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-lg-8 col-md-12">
        <div class="section-title">
          <div class="title-effect title-effect-2">
            <div class="ellipse"><h2 style={{color:"#fff"}}>i</h2></div> 
          </div>
          <h2 data-aos="zoom-in" data-aos-duration="1000" class="title">Services We Offer</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="post style-4">
          <div class="post-image">
            <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid h-100 w-100" src="assets/images/services/1.jpg" alt=""/>
          </div>
          <div class="post-desc">
           
            <div class="post-title">
              <h4 data-aos="zoom-in" data-aos-duration="1000">Clinical Trial Process and Document Management Application</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div class="post style-4">
          <div class="post-image">
            <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid h-100 w-100" src="assets/images/services/2.jpeg" alt=""/>
          </div>
          <div class="post-desc">
          
            <div class="post-title">
              <h4 data-aos="zoom-in" data-aos-duration="1000">Early Phase development
</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div class="post style-4">
          <div class="post-image">
            <img data-aos="zoom-in" data-aos-duration="1000"   class="img-fluid h-100 w-100" src="assets/images/services/3.jpg" alt=""/>
          </div>
          <div class="post-desc">
         
            <div class="post-title">
              <h4 data-aos="zoom-in" data-aos-duration="1000">Research Operation and Data Management
</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div class="post style-4">
          <div class="post-image">
            <img class="img-fluid h-100 w-100" src="assets/images/services/4.jpg" alt=""/>
          </div>
          <div class="post-desc">
         
            <div class="post-title">
              <h4 data-aos="zoom-in" data-aos-duration="1000">KOL Identification and site insights

</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
        <div class="post style-4">
          <div class="post-image">
            <img data-aos="zoom-in" data-aos-duration="1000" class="img-fluid h-100 w-100" src="assets/images/services/5.png" alt=""/>
          </div>
          <div class="post-desc">
         
            <div class="post-title">
              <h4 data-aos="zoom-in" data-aos-duration="1000">Training

</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="position-relative">
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-lg-8 col-md-12">
        <div class="section-title">
          <div class="title-effect title-effect-2">
            <div class="ellipse"></div> <i class="la la-cubes"></i>
          </div>
          <h2 class="title" data-aos="zoom-in" data-aos-duration="1000">Why CTPM</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-software"></i>
          </div>
         
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000"> Streamlined workflow for submissions and notifications for research activities at site level
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5 mt-md-0">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-scroll"></i>
          </div>
         
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000"> Ease of document management
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-resolution"></i>
          </div>
        
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000">Logins for various stake holders</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-objective"></i>
          </div>
         
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000"> Two-stages review process before submitting study documents to the Ethics Committee
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-market"></i>
          </div>
        
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000">Activity tracker/management - To set priority levels, TAT and site level operational metrics
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-customer-service"></i>
          </div>
        
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000">UI is self explanatory, help tools are available and hands on training will be provided by iKanekt</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-software"></i>
          </div>
         
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000"> Sponsor  - Access to multiple sites to initiate multicentric trial is a click away
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5 mt-md-0">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-scroll"></i>
          </div>
         
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000"> Sites - Options to manage guidelines, SOP’s, Regulatory documents, Hospital policies, Screening logs
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-resolution"></i>
          </div>
        
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000">Ethics committee management – SOP’s, Meeting scheduler, Opinion/voting, Meeting agenda, Members attendance
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-objective"></i>
          </div>
         
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000"> Notifications via email and bell icon for timelapse documents
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-market"></i>
          </div>
        
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000">Data archival – Post study completion archival can be triggered based on your requirements
</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-5">
        <div class="featured-item style-6">
          <div class="featured-icon"> <i class="flaticon-customer-service"></i>
          </div>
        
          <div class="featured-desc">
            <p data-aos="zoom-in" data-aos-duration="1000">eCRF, customization and management</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>























</div>


<footer class="footer footer-1 position-relative" data-bg-img="assets/images/bg/05.png" data-aos="zoom-in" data-aos-duration="1000">
  <div class="subscribe-box">
    <div class="container">
      <div class="row subscribe-inner align-items-center">
        <div class="col-md-6 col-sm-12">
          <h4>Subscribe Our Newsletter</h4>
        </div>
        <div class="col-md-6 col-sm-12 mt-3 mt-md-0">
          <div class="subscribe-form">
            <form id="mc-form" class="group">
              <input type="email" value="" name="EMAIL" class="email" id="mc-email" placeholder="Email Address" required=""/>
              <input class="btn btn-theme" type="submit" name="subscribe" value="Subscribe"/>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="primary-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-logo">
            <img id="footer-logo-img" src="assets/images/ikanekt_logo.png" class="img-fluid" alt=""/>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 ps-md-5 mt-5 mt-md-0 footer-list justify-content-between d-flex">
          <ul class="list-unstyled w-100">            
            <li>About Us
            </li>
            <li>Service
            </li>
           
            <li>Contact Us
            </li>
          </ul>
    
        </div>
        <div class="col-lg-4 col-md-12 mt-5 mt-lg-0">
          <ul class="media-icon list-unstyled">
            <li>
              <p class="mb-0">Contact person – 
Srirang Ramamoorthy<br/>
Mobile - 9902260163<br/>
Email – srirang@ikanektrnd.com
              </p>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="secondary-footer">
    <div class="container">
      <div class="copyright">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12"> <span>Copyright 2024  | All Rights Reserved </span>
          </div>
          <div class="col-lg-6 col-md-12 text-lg-end mt-3 mt-lg-0">
            <div class="footer-social">
              <ul class="list-inline">
                <li class="me-2"><a href="#"><i class="fab fa-facebook-f"></i> Facebook</a>
                </li>
                <li class="me-2"><a href="#"><i class="fab fa-twitter"></i> Twitter</a>
                </li>
                <li><a href="#"><i class="fab fa-google-plus-g"></i> Google Plus</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>




</div>




<div class="color-customizer closed">
  <div class="color-button">
    <a class="opener" href="#"> <i class="fas fa-spinner fa-spin"></i>
    </a>
  </div>
  <div class="clearfix color-chooser text-center">
    <h4 class="text-theme font-w-8 mb-4">Softino With <span class="text-black font-w-5">Awesome Colors</span></h4>
    <ul class="colorChange clearfix">
      <li class="theme-default selected" title="theme-default" data-style="color-1"></li>
      <li class="theme-2" title="theme-2" data-style="color-2"></li>
      <li class="theme-3" title="theme-3" data-style="color-3"></li>
      <li class="theme-4" title="theme-4" data-style="color-4"></li>
      <li class="theme-5" title="theme-5" data-style="color-5"></li>
      <li class="theme-6" title="theme-6" data-style="color-6"></li>
    </ul>
    <div class="text-center mt-4">
      <a class="btn btn-theme btn-circle" href="#" data-text="Purchase Now">
       <span>P</span><span>u</span><span>r</span><span>c</span><span>h</span><span>a</span><span>s</span><span>e</span>
       <span> </span><span>N</span><span>o</span><span>W</span>
        </a>
    </div>
  </div>
</div>




<div class="scroll-top"><a class="smoothscroll" href="#top"><i class="flaticon-go-up-in-web"></i></a></div>
    </div>

  );
};

export default Services;
